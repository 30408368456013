angular.module('fingerink')
    .service('fingerinkSession', function (loginService, session, $rootScope) {

        this.checkTokenAlive = () => {
            return new Promise((resolve, reject) => {
                if (session.get() && session.get().token) {
                    if (moment(session.get().time).isBefore(moment().subtract(290, "minutes"))) {
                        reject();
                    } else {
                        if (moment(session.get().time).isBefore(moment().subtract(150, "minutes"))) {
                            fingerinkSession.renoveToken().then(resolve,reject);
                        }
                    }
                } else {
                    reject();
                }
            });
        };


        this.renoveToken = function () {
            return new Promise(function (resolve, reject) {
                loginService.renoveToken().then(function (response) {
                    if (response.data) {
                        session.put(response.data);
                        $rootScope.$broadcast("sessionChanged");
                        resolve();
                    } else {
                        reject();
                    }
                }, function () {
                    reject();
                });
            });
        };
    });
